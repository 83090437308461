import { twMerge } from "tailwind-merge";
import Circle from "../../icons/circle.svg?react";
import Hello from "../../icons/doctor-hello.svg?react";
import UserIcon from "../../icons/user-icon.svg?react";
import EmailIcon from "../../icons/email-icon.svg?react";
import PrivacyIcon from "../../icons/privacy-shield.svg?react";
import clsx from "clsx";

import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";

import Select from "react-select";

import {
	useDocumentTitle,
	useLocalStorage,
	useSessionStorage,
} from "@uidotdev/usehooks";

import { successOpts, errorOpts, processOpts } from "../../util/toast";

import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";

import {
	auth,
	analytics,
	db,
} from "../../util/firebase";

import { isValidEmail, getAuthError } from "../../util/util";

import Loading from "../../components/Loading";

import { useState, useEffect, useRef } from "react";
import { EMAIL_REGEX } from "../../util/constant";

const TRY_NOW = "trynow";
const ACCEPT_POLICY = "policy";
const INFO = "info";
const SIGNUP = "signup";

const components = {
	[TRY_NOW]: TryNow,
	[INFO]: Info,
	[ACCEPT_POLICY]: Policy,
	[SIGNUP]: Auth,
};

const LABEL_CLASS = "font-inter text-font-200 select-none text-[90%] ml-[2px]";

const INPUT_CLASS =
	"px-4 py-3 rounded-md bg-white border-[1px] border-stroke border-solid outline-none text-black placeholder:text-[85%] placeholder:text-font-200/85 disabled:bg-gray-200 disabled:cursor-not-allowed";

function isGreater(curr, comparator) {
	let curr_idx = Object.keys(components).indexOf(curr);
	let comp_idx = Object.keys(components).indexOf(comparator);
	if (curr_idx === -1 || comp_idx === -1) {
		return false;
	}

	return curr_idx > comp_idx;
}

function getNextScreenKey(curr, prev = false) {
	let componentsKeys = Object.keys(components);

	let curr_idx = componentsKeys.indexOf(curr);

	curr_idx = prev
		? curr_idx - 1 < 0
			? 0
			: curr_idx - 1
		: (curr_idx + 1) % componentsKeys.length;

	let next_key = componentsKeys[curr_idx];

	return next_key;
}

function ParentLoader() {
	return (
		<div className="w-full h-full flex flex-col flex-grow justify-center items-center relative">
			<img
				src="https://cdn.chiroscript.ai/logo/logo.svg?v=8108891128"
				className="mt-4 absolute top-0"
				width={200}
				height={200}
			/>
			<Loading classes={"w-[4rem]"} />
		</div>
	);
}

export default function Welcome() {
	const { pathname } = useLocation();
	const isLogin = pathname.indexOf("login") !== -1;

	//   console.log({ isLogin });

	const [screen, setScreen] = useState(isLogin ? SIGNUP : TRY_NOW);
	const [screenNo, setScreenNo] = useState(
		Object.keys(components).indexOf(screen)
	);

	const navigate = useNavigate();

	const [user, userLoading, error] = useAuthState(auth);

	const [loading, setLoading] = useState(true);

	const [data, setData] = useState({});

	const getData = () => data;

	const Screen = components[screen];

	useEffect(() => {
		if (Object.keys(components).indexOf(screen) === screenNo)
			return () => false;

		setScreenNo(Object.keys(components).indexOf(screen));

		return () => true;
	}, [screen]);

	useEffect(() => {
		if (!auth || userLoading) return () => false;

		if (auth && auth.currentUser) {
			setScreen(SIGNUP);
		} else if (auth) setLoading(false);
	}, [user, userLoading, error, auth]);

	if (loading && !auth.currentUser) {
		return <ParentLoader />;
	}

	if (isLogin || screen === SIGNUP) {
		// console.log({
		//   isLogin,
		// });
		return (
			<Screen
				data={{
					set: setData,
					get: getData,
				}}
				screen={{
					parentLoading: loading,
					screen,
					screenNo,
				}}
				next={(prev = false) => setScreen(getNextScreenKey(screen, prev))}
			/>
		);
	} else {
		return (
			<>
				<div className="w-full h-full bg-page flex flex-col items-center relative">
					<div className="w-full justify-center items-center flex flex-col gap-4">
						<img
							src="https://cdn.chiroscript.ai/logo/logo.svg?v=8108891128"
							className="mt-4"
							width={200}
							height={200}
						/>
						{isGreater(screen, TRY_NOW) && (
							<button
								className="w-[40px] h-[40px] fixed left-4 top-4 border-gray-400 rounded-md border-2 justify-center items-center flex transition-all duration-500 ease-in-out shadow-md hover:shadow-none"
								onClick={() => {
									setScreen(getNextScreenKey(screen, true));
								}}
							>
								<svg
									width="18"
									height="18"
									viewBox="0 0 16 16"
									fill="#004CA9"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10 13.78C9.87335 13.78 9.74669 13.7333 9.64669 13.6333L5.30002 9.28668C4.59335 8.58001 4.59335 7.42001 5.30002 6.71335L9.64669 2.36668C9.84002 2.17335 10.16 2.17335 10.3534 2.36668C10.5467 2.56001 10.5467 2.88001 10.3534 3.07335L6.00669 7.42001C5.68669 7.74001 5.68669 8.26001 6.00669 8.58001L10.3534 12.9267C10.5467 13.12 10.5467 13.44 10.3534 13.6333C10.2534 13.7267 10.1267 13.78 10 13.78Z"
										fill="#004CA9"
									/>
								</svg>
							</button>
						)}
						{isGreater(screen, TRY_NOW) && (
							<div className="flex items-center gap-4">
								{Object.keys(components).map((item, index, arr) => (
									<div
										key={item}
										className={twMerge(
											"relative w-[59px] h-[6px] bg-gray-200 cursor-pointer",
											clsx(
												{ "rounded-l-full": index == 0 },
												{ "rounded-r-full": index + 1 == arr.length }
											)
										)}
									>
										<div
											className={twMerge(
												"absolute inset-0 from-gradient-start to-gradient-end from-[0%] to-[100%] bg-gradient-90 duration-200 transition origin-left scale-x-0",
												clsx(
													{ "rounded-l-full": index == 0 },
													{
														"scale-x-100": isGreater(screen, item),
													},
													{ "rounded-r-full": index + 1 == arr.length }
												)
											)}
										></div>
									</div>
								))}
							</div>
						)}
					</div>

					{
						<Screen
							data={{
								set: setData,
								get: getData,
							}}
							next={(prev = false) => setScreen(getNextScreenKey(screen, prev))}
						/>
					}
				</div>
				<ToastContainer stacked />
			</>
		);
	}
}

function getLocation(next) {
	let goto = "/";

	if (next) {
		goto = decodeURIComponent(next);
	}

	if (goto.charAt(0) !== "/") {
		goto = `/${goto}`;
	}

	return goto;
}

function Auth({ data, next, screen }) {
	const { pathname } = useLocation();

	const isLogin = pathname.indexOf("login") !== -1;

	const [manualRedirect, setManualRedirect] = useState(false);

	const navigate = useNavigate();

	const [searchParams, _] = useSearchParams();

	const nextParam = searchParams.get("next");

	console.log(nextParam);

	const [user, userLoading, error] = useAuthState(auth);

	const [loading, setLoading] = useState(true);

	const [authMode, setAuthMode] = useState(isLogin ? "login" : "register");

	const [emailError, setEmailError] = useState(false);
	const resetEmailRef = useRef(null);

	const resetEmailDialogRef = useRef(null);
	
	const [ resetEmailBusy, setResetEmailBusy ] = useState(false);

	useEffect(() => {
		if (!userLoading && user && !manualRedirect) {
			navigate(getLocation(nextParam));
		}

		if (error) {
			console.error(error);
		}

		if (!userLoading && !user && !manualRedirect) {
			setLoading(false);
			setManualRedirect(true);
		}
	}, [user, userLoading, auth]);

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isValid, isLoading },
	} = useForm();

	let [email, password, confirm, first, last] = watch([
		"email",
		"password",
		"confirm",
		"first",
		"last",
	]);

	//   console.log({
	//     errors,
	//   });

	const onAuth = async () => {
		if (!isValidEmail(email)) {
			setError("email", {
				message: "Email address is invalid",
				type: "validate",
			});

			return false;
		}

		if (!isLogin) {
			if (!(typeof first === "string" && first.trim().length > 0)) {
				setError("first", {
					message: "First name is required",
					type: "validate",
				});
				return false;
			}

			if (!(typeof last === "string" && last.trim().length > 0)) {
				setError("last", {
					message: "Last name is required",
					type: "validate",
				});
				return false;
			}

			if (confirm !== password) {
				setError("password", {
					message: "Passwords do not match.",
					type: "validate",
				});

				setError("confirm", {
					message: "Passwords do not match.",
					type: "validate",
				});

				return false;
			}
		}

		if (!isValid) return false;



		const {
			signInWithEmailAndPassword,
			createUserWithEmailAndPassword,
			sendPasswordResetEmail,
			validatePassword,
			SignInMethod,
		} = await import("firebase/auth");

		const { logEvent, setUserId, setUserProperties } = await import(
			"firebase/analytics"
		);



		const fn =
			authMode === "login"
				? signInWithEmailAndPassword
				: createUserWithEmailAndPassword;

		// const status = await validatePassword(auth, password).catch( () => false);

		// if(status && authMode !== "login") {
		// 	const policy = status.passwordPolicy;

		// 	console.log({policy});
		// 	return;
		// }

		const displayName = `${first} ${last}`;

		setLoading(true);

		const currentToast = toast("Please wait while we try to authenticate you!", {
			...processOpts,
		});


		try {
			const res = await fn(auth, email, password);

			if (!isLogin) {
				const { updateDoc, doc } = await import("firebase/firestore");
				await updateDoc(doc(db, "users", res.user.uid), {
					displayName,
				});
			}

			setUserId(analytics, auth.currentUser.uid, {
				global: true,
			});

			logEvent(analytics, authMode, { method: SignInMethod.EMAIL_PASSWORD });

			const { role, beta } = await auth.currentUser
				.getIdTokenResult(true)
				.then((idToken) => {
					const role = idToken.claims?.role ?? "user";
					const beta = idToken.claims?.beta ?? false;
					return {
						role: role,
						beta: beta,
					};
				});

			setUserProperties(analytics, {
				role: role,
				release: beta ? "beta" : "stable",
			});

			toast.dismiss();

			if (manualRedirect) {
				navigate(getLocation(nextParam));
			}

		} catch (err) {

			console.error(err);

			let formerror;

			let fatal = false;

			// console.log(err.message.indexOf('Cloud Function'));

			if (
				err.code === "auth/internal-error" &&
				err.message.indexOf("Cloud Function") !== -1
			) {
				formerror = err.message.substring(
					err.message.indexOf("{"),
					err.message.lastIndexOf("}") + 1,
				);
				formerror = JSON.parse(formerror);
				formerror = formerror.error.message;
			} else {
				formerror = getAuthError(err.code, err.message, isLogin ? "login" : "register");
				// console.log(err.code)
				if (err.code.indexOf("auth/password-does-not-meet-requirement") !== -1 && isLogin) {
					console.info("Password requirement not met")
					await sendPasswordResetEmail(auth, email, {
						url: "https://app.chiroscript.ai",
					});
				} else if (err.code === "auth/internal-error") {
					fatal = true;
				}
			}


			logEvent(analytics, "exception", {
				description: JSON.stringify({
					code: err.code,
					message: err.message,
					display: formerror,
				}),
				fatal,
			});

			toast.update(currentToast, {
				render: formerror,
				...errorOpts,
			});

			setLoading(false);

		}
	};

	const onGoogle = async (event) => {
		event?.preventDefault();

		// eslint-disable-next-line no-unused-vars
		const { signInWithPopup, GoogleAuthProvider } = await import(
			"firebase/auth"
		);

		const { logEvent, setUserId, setUserProperties } = await import(
			"firebase/analytics"
		);

		setLoading(true);

		try {
			await signInWithPopup(auth, new GoogleAuthProvider());

			logEvent(analytics, authMode, {
				method: GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD,
			});

			setUserId(analytics, auth.currentUser.uid, {
				global: true,
			});

			if (manualRedirect) {
				navigate(getLocation(nextParam));
			}
		} catch (err) {
			console.error(err, err.code);

			let formerror;

			let fatal = false;

			if (
				err.code === "auth/internal-error" &&
				err.message.indexOf("Cloud Function") !== -1
			) {
				formerror = err.message.substring(
					err.message.indexOf("{"),
					err.message.lastIndexOf("}") + 1
				);
				formerror = JSON.parse(formerror);
				formerror = formerror.error.message;
			} else {
				formerror = getAuthError(err.code);
				if (err.code === "auth/internal-error") {
					fatal = true;
				}
			}

			toast(formerror, {
				...errorOpts,
			});

			logEvent(analytics, "exception", {
				description: JSON.stringify({
					code: err.code,
					message: err.message,
					display: formerror,
				}),
				fatal,
			});

			setLoading(false);
		}
	};

	useDocumentTitle(
		screen?.parentLoading
			? "chiroscript.ai"
			: isLogin
				? "Login | chiroscript.ai"
				: "Register | chiroscript.ai"
	);

	if (screen?.parentLoading === true) {
		return <ParentLoader />;
	}

	return (
		<>
			<div className="flex flex-row w-full h-full bg-page">
				<div className="hidden lg:block relative w-full h-full bg-[url('https://cdn.chiroscript.ai/dashboard/left-image.png')] bg-cover bg-no-repeat">
					<div className="absolute top-0 left-0 w-full h-full from-gradient-start/70 to-gradient-end/70 from-[0%] to-[100%] bg-gradient-180"></div>
				</div>
				<div className="w-full h-full bg-page flex flex-col items-center relative">
					<div className="w-full justify-center items-center flex flex-col gap-4">
						<img
							src="https://cdn.chiroscript.ai/logo/logo.svg?v=8108891128"
							className="mt-4"
							width={200}
							height={200}
						/>
						{isGreater(screen.screen, TRY_NOW) && !isLogin && (
							<button
								className="w-[40px] h-[40px] fixed left-4 top-4 bg-white rounded-md border-2 justify-center items-center flex transition-all duration-500 ease-in-out shadow-md hover:shadow-none"
								onClick={() => next(true)}
							>
								<svg
									width="18"
									height="18"
									viewBox="0 0 16 16"
									fill="#004CA9"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10 13.78C9.87335 13.78 9.74669 13.7333 9.64669 13.6333L5.30002 9.28668C4.59335 8.58001 4.59335 7.42001 5.30002 6.71335L9.64669 2.36668C9.84002 2.17335 10.16 2.17335 10.3534 2.36668C10.5467 2.56001 10.5467 2.88001 10.3534 3.07335L6.00669 7.42001C5.68669 7.74001 5.68669 8.26001 6.00669 8.58001L10.3534 12.9267C10.5467 13.12 10.5467 13.44 10.3534 13.6333C10.2534 13.7267 10.1267 13.78 10 13.78Z"
										fill="#004CA9"
									/>
								</svg>
							</button>
						)}
						{isGreater(screen.screen, TRY_NOW) && !isLogin && (
							<div className="flex items-center gap-4">
								{Object.keys(components).map((item, index, arr) => (
									<div
										key={item}
										className={twMerge(
											"relative w-[59px] h-[6px] bg-gray-200",
											clsx(
												{ "rounded-l-full": index == 0 },
												{ "rounded-r-full": index + 1 == arr.length }
											)
										)}
									>
										<div
											className={twMerge(
												"absolute inset-0 from-gradient-start to-gradient-end from-[0%] to-[100%] bg-gradient-90 duration-200 transition origin-left scale-x-0",
												clsx(
													{
														"rounded-l-full": index == 0,
													},
													{
														"scale-x-100": isGreater(screen.screen, item),
													},
													{
														"rounded-r-full": index + 1 == arr.length,
													}
												)
											)}
										></div>
									</div>
								))}
							</div>
						)}
					</div>
					{loading && (
						<div className="flex flex-col justify-center items-center my-auto gap-4">
							<Loading classes={"w-[4rem]"} />
						</div>
					)}

					{!loading && (
						<div className="flex-col w-full lg:w-[600px] h-full justify-center mx-auto flex bg-page">
							<div className={twMerge("relative mx-auto mb-2", clsx({
								"hidden": !isLogin,
							}))}>
								<EmailIcon className="w-[90px] h-[90px]" />
							</div>
							<form
								onSubmit={handleSubmit(onAuth)}
								key={authMode}
								className="flex flex-col gap-3 p-4 lg:p-0"
							>
								{!isLogin && (
									<div
										className={twMerge(
											"flex flex-col lg:flex-row mt-3 gap-5",
											clsx({
												hidden: isLogin,
											})
										)}
									>
										<div className="flex flex-col gap-2 w-full">
											<label htmlFor="first" className={twMerge(LABEL_CLASS)}>
												First Name *
											</label>
											<input
												type="text"
												id="first"
												className={twMerge(
													INPUT_CLASS,
													clsx({
														error: errors["first"],
													})
												)}
												placeholder="First Name"
												disabled={isLoading || loading}
												{...register("first", {
													required: "Your first name is required",
												})}
											/>
											{typeof errors["first"] !== "undefined" && (
												<p className="ml-1 text-[80%] leading-[0.9] font-semibold -mt-[1.2px] text-red-500">
													{errors["first"].message}
												</p>
											)}
										</div>
										<div className="flex flex-col gap-2 w-full">
											<label htmlFor="last" className={twMerge(LABEL_CLASS)}>
												Last Name *
											</label>
											<input
												type="text"
												id="last"
												className={twMerge(
													INPUT_CLASS,
													clsx({
														error: errors["last"],
													})
												)}
												disabled={isLoading || loading}
												placeholder="Last Name"
												{...register("last", {
													required: "Your last name is required",
												})}
											/>
											{typeof errors["last"] !== "undefined" && (
												<p className="ml-1 text-[80%] leading-[0.9] font-semibold -mt-[1.2px] text-red-500">
													{errors["last"].message}
												</p>
											)}
										</div>
									</div>
								)}
								<div className="flex flex-col gap-2">
									<label htmlFor="email" className={twMerge(LABEL_CLASS)}>
										Email Address *
									</label>
									<input
										type="email"
										id="email"
										className={twMerge(
											INPUT_CLASS,
											clsx({
												error: errors["email"],
											})
										)}
										disabled={isLoading || loading}
										placeholder="user@example.com"
										{...register("email", {
											required: "Email Address is required",
										})}
									/>
									{typeof errors["email"] !== "undefined" && (
										<p className="ml-1 text-[80%] leading-[0.9] font-semibold -mt-[1.2px] text-red-500">
											{errors["email"].message}
										</p>
									)}
								</div>
								<div className="flex flex-col lg:flex-row gap-5">
									<div className="flex flex-col gap-2 w-full">
										<label htmlFor="password" className={twMerge(LABEL_CLASS)}>
											Password *
										</label>
										<input
											type="password"
											id="password"
											className={twMerge(
												INPUT_CLASS,
												clsx({
													error: errors["password"],
												})
											)}
											disabled={isLoading || loading}
											placeholder={isLogin ? "Your Password" : "Strong password"}
											{...register("password", {
												required: "Password is required",
											})}
										/>
										{typeof errors["password"] !== "undefined" && (
											<p className="ml-1 text-[80%] leading-[0.9] font-semibold -mt-[1.2px] text-red-500">
												{errors["password"].message}
											</p>
										)}
										<p onClick={() => {
											resetEmailDialogRef.current.showModal();
										}} className={twMerge("flex font-inter text-font-200 gap-2 justify-end text-[90%]", clsx({
											"hidden": !isLogin,
										}))}>Cannot Log in? <a
											role="button"
											className="link link-hover text-gradient-start underline text-center"
										>
											Forget Password
										</a></p>
									</div>
									{!isLogin && (
										<div
											className={twMerge(
												"flex flex-col gap-2 w-full",
												clsx({
													hidden: isLogin,
												})
											)}
										>
											<label
												htmlFor="cpassword"
												className={twMerge(LABEL_CLASS)}
											>
												Confirm Password *
											</label>
											<input
												type="password"
												id="cpassword"
												className={twMerge(
													INPUT_CLASS,
													clsx({
														error: errors["confirm"],
													})
												)}
												disabled={isLoading || loading}
												placeholder="Confirm your password"
												{...register("confirm", {
													required: "Confirm Password is required",
												})}
											/>
											{typeof errors["confirm"] !== "undefined" && (
												<p className="ml-1 text-[80%] leading-[0.9] font-semibold -mt-[1.2px] text-red-500">
													{errors["confirm"].message}
												</p>
											)}
										</div>
									)}
								</div>
								<div className="flex flex-col justify-center items-center gap-1">
									<button className="btn flex flex-row mt-[0.8rem] mx-auto bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center focus:border-primary focus:border-[1px] focus:border-solid">
										<span>{isLogin ? "Login" : "Get Started"}</span>
									</button>
									<p className="flex font-inter text-font-200 gap-2 justify-center text-[90%]">
										{isLogin
											? "Don't have an account?"
											: "Already have an account?"}
										<a
											href={isLogin ? "/welcome" : "/login"}
											className="link link-hover text-gradient-start underline text-center"
										>
											{!isLogin ? "Sign in" : "Sign Up"}
										</a>
									</p>
								</div>
							</form>
							<div className="flex flex-row items-center justify-around gap-6 -mt-1 lg:mt-3">
								<div className="h-[1px] w-full border-[1px] border-gradient-start/80"></div>
								<p className="w-fit bg-transparent font-dm font-semibold text-primary">
									or
								</p>
								<div className="h-[1px] w-full border-[1px] border-gradient-end/80"></div>
							</div>
							<div className="flex flex-row justify-center gap-[1.875rem] mt-1 lg:mt-4 bg-page">
								<button
									className="w-[320px] mx-auto justify-center inline-flex cursor-pointer items-center gap-4 rounded-[0.4rem] bg-white p-3 font-dm text-[1rem] font-medium font-inter text-black shadow-sm transition-shadow duration-[500ms] hover:shadow-none"
									onClick={onGoogle}
								>
									<img
										src="https://cdn.chiroscript.ai/icons/google.svg"
										className="w-[1.2rem]"
										width={"19.2px"}
										loading="lazy"
									></img>
									<p>Continue with Google</p>
								</button>
							</div>
							<p className={twMerge("text-center mt-4 text-font-200 text-sm", {
								"hidden": !isLogin
							})}>Protected by reCAPTCHA and subject to the ChiroScript Privacy Policy and Terms of Use.</p>
						</div>
					)}
				</div>
			</div>
			<dialog className="modal modal-middle" ref={resetEmailDialogRef}>
				<div className="modal-box bg-white flex flex-col justify-center items-center gap-3">
					<button
						onClick={() => {
							setResetEmailBusy(false);
							resetEmailDialogRef.current.close();
						}}
						className={twMerge("btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-gray-500 hover:scale-95", clsx({
							"hidden": resetEmailBusy,
						}))}
					>
						✕
					</button>
					<svg aria-label="Trouble logging in?" className="fill-primary" fill="currentColor" height="64" role="img" viewBox="0 0 96 96" width="64"><title>Trouble logging in?</title><circle cx="48" cy="48" fill="none" r="47" stroke="currentColor" className="stroke-primary" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></circle><path d="M60.931 70.001H35.065a5.036 5.036 0 0 1-5.068-5.004V46.005A5.036 5.036 0 0 1 35.065 41H60.93a5.035 5.035 0 0 1 5.066 5.004v18.992A5.035 5.035 0 0 1 60.93 70ZM37.999 39.996v-6.998a10 10 0 0 1 20 0v6.998" fill="none" stroke="currentColor" className="stroke-primary" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path></svg>
					<h6 className="font-inter">Trouble logging in?</h6>
					<p className="text-font-200 text-center text-sm">Enter your email we'll send you a link to get back into your account.</p>
					<div className="flex flex-col gap-2 w-full">
						<input
							type="email"
							id="email"
							className={twMerge(
								INPUT_CLASS,
								clsx({
									error: emailError,
								})
							)}
							ref={resetEmailRef}
							disabled={isLoading || loading}
							placeholder="user@example.com"
						/>
						{emailError !== false && (
							<p className="ml-1 text-[80%] leading-[0.9] font-semibold -mt-[1.2px] text-red-500">
								{emailError}
							</p>
						)}
						<button onClick={async (e) => {

							e.preventDefault();
							e.stopPropagation();

							setResetEmailBusy(true);

							setEmailError(false);

							const email = resetEmailRef.current.value;

							if (!email || !EMAIL_REGEX.test(email)) {
								setEmailError("Email address is not valid");
								setResetEmailBusy(false);
								return;
							}

							const currentTarget = e.currentTarget;

							resetEmailRef.current.disabled = true;
							currentTarget.disabled = true;

							const id = toast("Please wait while we send you a password reset link.", {
								...processOpts
							});


							const { sendPasswordResetEmail } = await import("firebase/auth");
							const { auth } = await import("../../util/firebase");

							try {
								await sendPasswordResetEmail(auth, email, {
									url: window.location.protocol + "//" + window.location.host,
								});
							} catch (err) {

								console.error(err, "Password reset error");
							}


							toast.update(id, {
								render: "We have sent you a password reset link to your inbox.",
								...successOpts,
							});
							resetEmailRef.current.value = "";
							resetEmailRef.current.disabled = !true;
							currentTarget.disabled = !true;
							
							setResetEmailBusy(false);

							resetEmailDialogRef.current.close();


						}} className="btn flex flex-row bg-accent stroke-none outline-none border-none w-full hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.1rem] font-inter font-bold transition-all duration-500 ease-in-out justify-center focus:border-primary focus:border-[1px] focus:border-solid disabled:text-font-800 disabled:font-normal disabled:bg-gray-300 disabled:cursor-not-allowed">
							<span>Send Link</span>
						</button>
					</div>
				</div>
				{
					!resetEmailBusy && (<form method="dialog" className="modal-backdrop">
						<button></button>
					</form>)
				}
			</dialog>
			{<ToastContainer stacked />}
		</>
	);
}

function Policy({ data, next }) {
	const { set, get } = data;

	const obj = get();

	const [ticked, setTicked] = useState({
		health: true,
		policy: false,
	});

	function click() {
		if (
			Object.values(ticked).reduce((acc, curr) => acc && curr, true) === true
		) {
			//   console.log({
			//     ...obj,
			//     ...ticked,
			//   });
			set({
				...obj,
				...ticked,
			});
			next();
			return;
		}
	}

	useEffect(() => {
		let tt = ticked;

		// Marked for deletion check UAT
		/*
		if (typeof obj?.health === "boolean") {
		  tt = {
			...tt,
			health: obj?.health,
		  };
		}
		*/

		if (typeof obj?.policy === "boolean") {
			tt = {
				...tt,
				policy: obj?.policy,
			};
		}

		setTicked(tt);
	}, []);

	return (
		<div className="relative flex-grow h-full w-full flex flex-col items-center justify-center z-10 gap-8 mx-auto">
			<div className="relative">
				<PrivacyIcon className="w-[90px] h-[90px]" />
			</div>
			<div className="flex flex-col items-center justify-center *:leading-snug *:font-inter *:text-center">
				<h5>Data & Privacy</h5>
				<div className="flex flex-col gap-1 mt-4 *:font-inter *:font-[1rem] *:text-font-200">
					<p>We take privacy seriously.</p>
					<p>We're also HIPAA & GDPR compliant.</p>
				</div>
			</div>
			<div className="flex justify-center flex-col gap-4 w-[320px] ml-4">
				<div className={twMerge("flex flex-row gap-2 *:cursor-pointer", "hidden")}>
					<div className="flex flex-row gap-4 items-center *:cursor-pointer">
						<input
							type="checkbox"
							onChange={(evt) =>
								setTicked({
									...ticked,
									health: evt.target.checked,
								})
							}
							className="h-3 w-3 hover:accent-primary/95 accent-primary appearance-auto"
							id="health"
							defaultChecked={true}
						/>
						<label
							htmlFor={"health"}
							className="font-inter text-font-200  select-none"
						>
							I am a professional health provider
						</label>
					</div>
				</div>
				<div className="flex flex-row gap-4 items-center *:cursor-pointer w-[320px] -mt-2">
					<input
						type="checkbox"
						onChange={(evt) =>
							setTicked({
								...ticked,
								policy: evt.target.checked,
							})
						}
						className="h-3 w-3 hover:accent-primary/95 accent-primary appearance-auto"
						id="policy"
						defaultChecked={obj?.policy === true}
					/>
					<label
						htmlFor={"policy"}
						className="font-inter text-font-200  select-none"
					>
						I agree to the Terms of Service and the Data Protection Agreement.
					</label>
				</div>
			</div>
			<button
				className="btn flex flex-row bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.1rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center disabled:bg-gray-300 disabled:font-inter disabled:font-normal disabled:!cursor-not-allowed disabled:text-font-200 disabled:hover:bg-gray-300 disabled:hover:font-inter disabled:hover:font-normal disabled:hover:!cursor-not-allowed disabled:hover:text-font-200"
				disabled={
					!Object.values(ticked).reduce((acc, curr) => acc && curr, true)
				}
				onClick={click}
			>
				Next
			</button>
		</div>
	);
}

const countryOptions = [
	{ value: "India", label: "India" },
	{ value: "USA", label: "United States of America" },
	{ value: "Canada", label: "Canada" },
];

const ehrOptions = [
	{ value: "SIMPLEPRACTICE", label: "SimplePractice" },
	{ value: "OPUS", label: "Opus" },
	{ value: "MEDITABIMS", label: "Meditab IMS" },
	{ value: "ECLINICALWORKS", label: "eClinicalWorks" },
	{ value: "ATHENAHEALT", label: "Athenahealth" },
	{ value: "ADVANCEDMD", label: "AdvancedMD" },
	{ value: "CERNER", label: "Cerner" },
	{ value: "KAREO", label: "Kareo" },
	{ value: "THERAPYNOTES", label: "TherapyNotes" },
	{ value: "CPSI", label: "CPSI" },
	{ value: "NEXTGEN", label: "NextGen" },
	{ value: "EPIC", label: "Epic" },
	{ value: "PRACTICEFUSION", label: "Practice Fusion" },
];

function Info({ data, next }) {
	const country = useRef(null);
	const ehr = useRef(null);

	const { set, get } = data;

	const obj = get();

	//   console.log(obj);

	function click() {
		const whatCountry = country.current.getValue();
		const whatEhr = ehr.current.getValue();

		if (!Array.isArray(whatCountry) || whatCountry.length == 0) {
			const current = toast("Please select a country to continue", {
				...errorOpts,
			});

			return;
		}

		set({
			...obj,
			country: whatCountry[0].value,
			ehr:
				Array.isArray(whatEhr) && whatEhr.length > 0 ? whatEhr[0].value : null,
		});

		if (typeof next === "function") {
			next();
		}
	}

	return (
		<div className="relative flex-grow h-full w-full flex flex-col items-center justify-center z-10 gap-4 mx-auto">
			<div className="relative">
				<UserIcon className="w-[90px] h-[90px]" />
			</div>
			<div className="flex flex-col items-center justify-center *:leading-snug *:font-inter *:text-center">
				<h5>Tell us more</h5>
				<h5>about your practice</h5>
			</div>
			<div className="flex justify-center flex-col items-center gap-6 mt-4">
				<div className="flex flex-col gap-2 *:cursor-pointer">
					<label className="font-inter text-font-200 font-semibold text-[90%]">
						Country of practice *
					</label>
					<Select
						isSearchable={true}
						className="w-[320px] !cursor-pointer"
						options={countryOptions}
						defaultValue={
							typeof obj?.country === "string" && obj.country.length > 0
								? countryOptions.filter((item) => item.value === obj.country)[0]
								: countryOptions[1]
						}
						required={true}
						ref={country}
					/>
				</div>
				<div className="flex flex-col gap-2 *:cursor-pointer text-[90%] hidden">
					<label className="font-inter text-font-200 font-semibold">EHR</label>
					<Select
						isSearchable={true}
						className="w-[320px] !cursor-pointer"
						options={ehrOptions}
						defaultValue={
							typeof obj?.ehr === "string" && obj.ehr.length > 0
								? ehrOptions.filter((item) => item.value === obj.ehr)[0]
								: null
						}
						ref={ehr}
					/>
				</div>
			</div>
			<button
				className="btn flex flex-row mt-3 bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center"
				onClick={click}
			>
				<span>Next</span>
			</button>
		</div>
	);
}

function TryNow({ data, next }) {
	function click() {
		if (typeof next === "function") {
			next();
		}
	}

	return (
		<div className="relative flex-grow h-full w-full flex flex-col items-center justify-center z-10 gap-10 mx-auto">
			<div className="relative">
				<Circle className="w-[100px] h-[100px]" />
				<Hello className="w-[100px] h-[100px] absolute shadow-none top-0 mt-2" />
			</div>
			<div className="flex flex-col items-center justify-center gap-5">
				<h3 className="font-inter font-semibold">Welcome</h3>
				<div className="text-center *:font-inter *:text-[1rem] text-font-200">
					<p>AI Medical Scribe</p>
					<p>for Chiropractic Clinics</p>
				</div>
				<div className="flex flex-col gap-3">
					<button
						className="btn flex flex-row bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center"
						onClick={click}
					>
						<span className="translate-x-1">Try Now</span>
						<svg
							width="18"
							height="18"
							viewBox="0 0 16 16"
							className="rotate-180 group-hover:translate-x-1 duration-500"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10 13.78C9.87335 13.78 9.74669 13.7333 9.64669 13.6333L5.30002 9.28668C4.59335 8.58001 4.59335 7.42001 5.30002 6.71335L9.64669 2.36668C9.84002 2.17335 10.16 2.17335 10.3534 2.36668C10.5467 2.56001 10.5467 2.88001 10.3534 3.07335L6.00669 7.42001C5.68669 7.74001 5.68669 8.26001 6.00669 8.58001L10.3534 12.9267C10.5467 13.12 10.5467 13.44 10.3534 13.6333C10.2534 13.7267 10.1267 13.78 10 13.78Z"
								fill="currentColor"
							/>
						</svg>
					</button>
					<p className="flex font-inter text-font-200 gap-2 justify-center">
						Already have an account?
						<a
							href="/login"
							className="link link-hover text-gradient-start underline text-center"
						>
							Sign in
						</a>
					</p>
				</div>
			</div>
		</div>
	);
}
