import { useEffect, useState, useContext, useReducer, createContext, useRef } from "react";
import { auth, db, analytics, messaging } from '../../../util/firebase';
import Snackbar from 'node-snackbar'
import { onMessage } from "firebase/messaging";

import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { setUserId } from "firebase/analytics";

import { useLocalStorage, useSessionStorage, useDocumentTitle, usePrevious, useDebounce, useMediaQuery } from "@uidotdev/usehooks";
import { collection, onSnapshot, query, where, doc, orderBy, getDocs, addDoc } from "firebase/firestore";

import Loading from "../../../components/Loading";
import useDevice from "../../../hooks/useDevice";
import { getJWT } from "../../../util/util";

import Fuse from "fuse.js";

const DashboardContext = createContext({
	currentUser: {}, location: {
		navigate: (to, options = {}) => { return; },
		currentPath: ""
	}, pwa: {}, payload: { encounters: [], patients: [] }, set: {
		title: (t) => { return; }
	}
});


// eslint-disable-next-line react-refresh/only-export-components
export function useDashboard() {
	return useContext(DashboardContext);
}


const reducer = (state, action) => {

	switch (action.type) {
		case 'NEW':
			return [...state, {
				...action?.payload ?? {}
			}];

		case 'REMOVE': return state.filter((resource) => resource.id !== action.payload.id);

		case 'UPDATE':
			return state.map(resource => {
				if (resource.id === action.payload.id) {
					return action?.payload;
				} else {
					return resource;
				}
			});

		case 'default':
			return state;

	}


}


const greet = () => {
	let hour = new Date().getHours();
	let greeting;
	if (hour < 12) {
		greeting = "Good Morning";
	} else if (hour < 18) {
		greeting = "Good Afternoon";
	} else {
		greeting = "Good Evening";
	}
}


// eslint-disable-next-line react/prop-types
export default function DashboardProvider({ children }) {

	const [user, loading,] = useAuthState(auth)
	const [loader, setLoader] = useState(true);
	const [pwa, setPWA] = useState({
		deferredPrompt: null,
		promote: false,
	});

	const device = useDevice();


	const [encounters, dispatchEncounter] = useReducer(reducer, []);
	const [_patients, dispatchPatient] = useReducer(reducer, []);

	const [patients, setPatients] = useState(_patients ?? []);

	const [pwaDismiss, setPWADismiss] = useLocalStorage("pwadismiss", "no");
	const [apzi, setApzi] = useState(false);

	const [isPatientModalVisible, setIsPatientModalVisible] = useState(false);
	const [patientModalType, setPatientModalType] = useState(null);

	const [searchParams, setSearchParams] = useSearchParams();

	const search = searchParams.get("search");

	const [sort, setSort] = useState("asc");

	const lastSort = usePrevious(sort);

	const deboucedSearch = useDebounce(search, 500);

	const previousSearch = usePrevious(deboucedSearch);

	const sidebar = useRef(null);

	const [expanded, setExpanded] = useState(false);


	const toggleSidebar = (e) => {

		e?.preventDefault();
		e?.stopPropagation();

		// do something here;
		if (!expanded) {
			sidebar.current.showModal();
		} else {
			sidebar.current.close();
		}

		let tmp = !expanded;
		setExpanded(tmp);

	}

	useEffect(() => {
		onSorting(sort, true, _patients, deboucedSearch);

		return () => false;


	}, [deboucedSearch]);


	const searchNow = (deboucedSearch, list) => {
		const fuse = new Fuse(list, {
			keys: ["name"],
		});


		const r = fuse.search(deboucedSearch);

		if (r.length > 0) {
			const p = r.map(i => i?.item);
			return p;
		} else {
			return [];
		}


	}
	const onSearch = (val) => {
		if (val.toString().trim().length === 0) {
			searchParams.delete("search");
		} else {
			searchParams.set("search", val)
		}
		setSearchParams(searchParams, {
			replace: true,
		});
	}

	const togglePatientModal = (mode, type) => {
		setPatientModalType(type);
		setIsPatientModalVisible(mode);
	}

	const onSorting = (e, sync = false, list = undefined, searchTerm = undefined) => {
		let current = sort;

		list = list ?? patients
		searchTerm = searchTerm ?? deboucedSearch;

		if (sync === true) {
			const rp = current === "asc" ? list : list.reverse();
			let p = rp;
			if (searchTerm) {
				p = searchNow(searchTerm, p);
			}
			setPatients((() => [...p]))
		} else {
			setPatients(((p) => [...p.reverse()]));
		}

		setSort(e);

		return true;

	}

	const [userDetails, setUserDetails] = useState({
		verified: null,
		approved: true,
		displayName: null,
		stable: false,
	});

	useEffect(() => {
		if (user !== null && db !== null) {
			// console.log(user.uid);


			return onSnapshot(query(collection(db, 'encounters'), where("uid", "==", user.uid), orderBy("createdAt", "desc")), (snapshot) => {
				// console.log(snapshot)
				snapshot.docChanges().forEach((change) => {
					let type;
					if (change.type === 'added') {
						type = 'NEW';
					} else if (change.type === 'modified') {
						type = 'UPDATE'
					} else if (change.type === 'removed') {
						type = 'REMOVE'
					}

					// if(type)

					let payload = change.doc.data({
						serverTimestamps: 'estimate'
					});

					let id = change.doc.id;

					if (payload && typeof type === 'string') {

						dispatchEncounter({ type, payload: { id, ...payload } });

					}
				});
			})
		}
	}, [user, db]);

	useEffect(() => {

		if (_patients.length === 0 && patients.length === 0) return () => false;
		onSorting(sort, true, _patients);

	}, [_patients])


	useEffect(() => {
		if (user !== null && db !== null) {
			// console.log(user.uid);
			// if(prevSort !== sort && prevSort !== null) dispatchPatient({ type: "EMPTY" })

			return onSnapshot(query(collection(db, 'patients'), where("uid", "==", user.uid), where("trash", "==", false), orderBy("first", "asc")), (snapshot) => {
				// console.log(snapshot)
				snapshot.docChanges().forEach((change) => {

					let type;
					if (change.type === 'added') {
						type = 'NEW';
					} else if (change.type === 'modified') {
						type = 'UPDATE'
					} else if (change.type === 'removed') {
						type = 'REMOVE'
					}

					let payload = change.doc.data({
						serverTimestamps: 'estimate'
					});

					let id = change.doc.id;

					let patient = (patients ?? []).filter((item) => item?.id === id);

					if (patient.length > 0 && type === "NEW") {
						type = "UPDATE";
					}

					if (payload && typeof type === 'string') {

						dispatchPatient({ type, payload: { id, ...payload } });

					}
				});
			})
		}
	}, [user, db]);

	// console.log(patients, encounters);
	const isLandscape = useMediaQuery(
		"(orientation: landscape)"
	);

	const isPhone = (device === "phone");



	useEffect(() => {

		window.addEventListener('beforeinstallprompt', (e) => {
			// Prevent the mini-infobar from appearing on mobile
			e.preventDefault();

			setPWA(() => ({
				deferredPrompt: e,
				promote: true,
			}));

			// console.log(`'beforeinstallprompt' event was fired.`);
		});

		window.addEventListener('appinstalled', () => {

			setPWA(() => ({
				deferredPrompt: null,
				promote: false,
			}));

			// console.log('PWA was installed');
		});

	}, []);

	useEffect(() => {

		if (!loading) {
			if (!user) {

				let next = encodeURIComponent(window.location.pathname.substring(1));

				if (next.length === 0) {
					navigate(`/welcome`);
				} else {
					navigate(`/login?next=${next}`);
				}

			} else {
				setUserId(analytics, user.uid);

				return onSnapshot(doc(db, "users", user.uid), async (snapshot) => {
					if (!snapshot.exists()) {
						return false;
					}

					const data = snapshot.data({
						serverTimestamps: "estimate"
					});

					if (!apzi) {
						// console.log(data.createdAt, new Date(data.createdAt.seconds))
						window.appziSettings = {
							'userId': data.uid,
							data: {
								'uid': data.uid,
								'premium': data.subscribed,
								'role': data.role,
								'email': data.email,
							},
						}

						setApzi(true);
					}

					const idToken = await getJWT(false);

					// console.log({
					// 	jwt: idToken
					// })

					setUserDetails({
						...data,
						idToken,
						stable: true,
					});

					if (loader) {
						setLoader(false);
					}

				}, (err) => console.error("Something went wrong", err));
			}
		}

	}, [loading, user]);

	async function installApp() {
		if (pwa && pwa.promote && pwa.deferredPrompt) {
			let { deferredPrompt } = pwa;

			setPWA((prev) => ({
				...prev,
				promote: false,
			}));

			deferredPrompt.prompt();

			// Wait for the user to respond to the prompt
			const { outcome } = await deferredPrompt.userChoice;

			// console.log({
			//     outcome
			// });

			console.log(`User response to the install prompt: ${outcome}`);

			// We've used the prompt, and can't use it again, throw it away
			// DO NOT NEED TO DO THIS AS THIS IS REACT.JS
			deferredPrompt = null;
		}
	}


	useEffect(() => {
		window.addEventListener("swupdatefound", () => {
			Snackbar.show({
				actionText: "Reload",
				onActionClick: () => window.location.reload(),
				"text": "ChiroScript.ai just got updated. Please reload to quickly get the latest version.",
			})
		})
	}, []);


	useEffect(() => {
		return onMessage(messaging, async (payload) => {

			console.log("Frontend Message recieved ", payload);

			const notificationTitle = payload.data.title;
			const options = JSON.parse(payload.data.options);
			const notificationOptions = {
				...options,
				vibrate: (new Boolean(options.vibrate)).valueOf(),
				renotify: (new Boolean(options.renotify)).valueOf(),
				icon: "/assets/icon-bg-72.png",
			};

			console.log("Payload structured ", notificationOptions);

		})
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname;

	const value = {
		currentUser: {
			user,
			details: userDetails,
			auth
		},
		location: {
			navigate,
			currentPath,
			search,
			onSearch,
		},
		pwa: {
			isDesktop: device === "desktop",
			isTablet: device === "tablet",
			isPhone,
			installApp,
			dismiss: () => setPWADismiss("yes"),
			show: pwaDismiss === "no",
			...pwa,
		},
		payload: {
			encounters,
			patients,
			allPatients: _patients,
		},
		set: {
			title: (t) => useDocumentTitle(`${t} | ChiroScript.ai`),
			patient: {
				visible: isPatientModalVisible,
				type: patientModalType,
				dismiss: () => togglePatientModal(false, null),
				show: (type) => togglePatientModal(true, type),
			},
		},
		sort: {
			callback: onSorting,
			value: sort,
		},
		sidebar: {
			e: sidebar,
			toggle: toggleSidebar,
			expanded,
		}
	}

	// console.log(isPhone, isLandscape)

	return (
		<DashboardContext.Provider value={value}>
			{
				(isPhone && isLandscape) ? <Rotate /> : ((loader || loading) ? <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col gap-y-3 items-center justify-center w-full">
					<Loading classes={"w-[5rem]"} />
					<span className="text-primary">Please wait while we prepare the dashboard</span>
				</div> : children)
			}
		</DashboardContext.Provider>
	)
}


function Rotate() {
	return (
		<div className="fixed w-full h-full top-0 left-0 bg-cover bg-[url('https://cdn.chiroscript.ai/dashboard/sValK.png')] bg-center"></div>
	)
}

