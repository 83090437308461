import { createBrowserRouter, RouterProvider, useLocation, useNavigate } from "react-router-dom";

import { useEffect } from "react";

import Loading from "./components/Loading";
import Error from "./components/Error";

import Welcome from "./pages/Welcome";
import Dashboard from "./pages/Dashboard";
import useDevice from "./hooks/useDevice";

const router = createBrowserRouter([
    {
      path: "/welcome",
      element: <Welcome />,
      errorElement: <Error />,
      loader: Loading
    },
    {
      path: "/login",
      element: <Welcome />,
      errorElement: <Error />,
      loader: Loading
    },
    {
      path: "/",
      element: <Dashboard />,
      errorElement: <Error />,
      loader: Loading
    },
	{
		path: "/auth",
		element: <Auth />,
		errorElement: <Error />,
		loader: Loading
	},
	{
		path: "/patients",
		element: <Dashboard />,
		errorElement: <Error />,
		loader: Loading
	},
	{
		path: "/patient/:id",
		element: <Dashboard />,
		errorElement: <Error />,
		loader: Loading
	},
	{
		path: "/encounters/:id",
		element: <Dashboard />,
		errorElement: <Error />,
		loader: Loading,
	}
]);


let wakeLock = null;

export default function App() {

	const device = useDevice();
	
	const isPhone = device === "phone";
	// console.log(device);

  useEffect(() => {
	if(isPhone) return () => false
    (function (d) {
      const appziScript = d.createElement("script");
		appziScript.async = true;
		appziScript.defer = true;
        appziScript.src = `https://w.appzi.io/w.js?token=sNdXX`;
        d.head.appendChild(appziScript);
    })(document);

    return () => false;
  }, []);


  async function documentVisibilityChange() {
    if (
      wakeLock === null &&
      document.visibilityState === "visible" &&
      "wakeLock" in navigator
    ) {
      // reacquire wake lock
      wakeLock = await navigator.wakeLock.request("screen");
    }

    if (
      document.visibilityState === "hidden" &&
      wakeLock !== null &&
      "wakeLock" in navigator
    ) {
      await wakeLock.release();
      wakeLock = null;
    }
  }

  useEffect( () => {
    documentVisibilityChange()
    
    document.addEventListener("visibilitychange", documentVisibilityChange);

    return () => document.removeEventListener("visibilitychange", documentVisibilityChange);
    
  }, []);

  return (
    <RouterProvider router={router} />
  );
}


function Auth() {

	const location = useLocation();
	const navigate = useNavigate();

	useEffect( () => {
		if(location.pathname.indexOf("auth") !== -1) {
			navigate('/');
		}
	}, []);

	return (<></>);
}
