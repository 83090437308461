import { sha1 } from "js-sha1";
import { DATE_FORMAT_PARAMETER, EMAIL_REGEX, ENCOUNTER_NOTE, ENCOUNTER_QME } from "./constant";

export function getPatientDocId(email, uid) {
	return sha1(`${email}|${uid}`).toString();
}

export async function isPatient(email) {
	
	const { auth, db } = await import("./firebase");

	const uid = auth?.currentUser?.uid;

	const patientId = getPatientDocId(email, uid);

	const { getDoc, doc } = await import("firebase/firestore");

	try {
		const res = await getDoc(doc(db, "patients", patientId));
		return res.exists();
	} catch(_err) {
		return false;
	}
}

export const isValidEmail = (email) => email.indexOf("@") !== -1 && email.indexOf("@") === email.lastIndexOf("@") && EMAIL_REGEX.test(email);


export function getTimeFormat(date, params = undefined, locale = "") {
	
	if(typeof locale !== "string") {
		locale = navigator.language || navigator.userLanguage || "en-US"
	};

	if(typeof locale !== "string" || locale.length === 0 || locale.indexOf("-") !== -1) {
		locale =  navigator.language || navigator.userLanguage || "en-US"
	}

	if(!date) {
		date = new Date()
	}
	
	return Intl.DateTimeFormat(locale, {
		hour: "2-digit",
		minute: "2-digit",
		// timeZoneName: "short",
		timeZone: undefined,
	}).format(date);


}

export const getDisplayType = (type ) => {
	if(type === ENCOUNTER_NOTE) {
		return "Note";
	} else if (type === ENCOUNTER_QME) {
		return "QME"
	}

	return null;
}

export function getDateFormat(date, params = undefined, locale = ""){

	if(typeof params === "undefined") {
		params = DATE_FORMAT_PARAMETER
	}

	params = {
		...DATE_FORMAT_PARAMETER,
		...params
	};

	for(let [key, value] of Object.entries(params) ) {
		if(typeof value === "undefined") {
			delete params[key];
		}
	}

	if(typeof locale !== "string") {
		locale = navigator.language || navigator.userLanguage || "en-US"
	};

	if(typeof locale !== "string" || locale.length === 0 || locale.indexOf("-") !== -1) {
		locale =  navigator.language || navigator.userLanguage || "en-US"
	}


	if(!date) {
		date = new Date();
	}

	
	return Intl.DateTimeFormat(locale, {
		...params
	}).format(date);
}

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function getAuthError(code, msg = null, mode = "login") {
    let formerror
    switch(code) {
        case 'auth/user-not-found':
            formerror = `It seems like you do not have an account with us! Continue to sign up first.`;
        break;
        case 'auth/account-exists-with-different-credential':
            formerror = `An account already exists with the same email address.`;
            break;
        case 'auth/email-already-in-use':
            formerror = "Seems like you already have an account! Contine to Sign In";
            break;
        case 'auth/invalid-credential':
        case 'auth/invalid-email':
        case 'auth/wrong-password':
            formerror = "You have provided an invalid email or password.";
            break;
		case 'auth/password-does-not-meet-requirements':
			
			if(mode === "login") {
				const r = "Password must contain";
				let m = msg.split(": ")?.at(-1);
				const idx = m.indexOf(`(${code})`);
				m = m.toString().substring(0, idx).trim()
				const arr = m.toString().substring(1, m.length - 1).split(",").map(item => item.trim()).map( (item) => {
					
					const idx = item.indexOf(r) + r.length;
					item = item.substring(idx).trim();
					return item;
				});
	
				formerror = r + " " + arr.map( (item) => {

					const last = arr.at(-1);

					if(last === item) {
						item = `and ${item}`;
					} else {
						item = item + ","
					}

					return item;
				}).join(" ")

				formerror = `Your are using a password which does not match our policies. ${formerror}. You need to upgrade your password according to our current policies. We have initiated a password reset for you and sent you an email.`

				// give a message, and send a forget password link
			} else {
				const r = "Password must contain";
				let m = msg.split(": ")?.at(-1);
				const idx = m.indexOf(`(${code})`);
				m = m.toString().substring(0, idx).trim()
				const arr = m.toString().substring(1, m.length - 1).split(",").map(item => item.trim()).map( (item) => {
					
					const idx = item.indexOf(r) + r.length;
					item = item.substring(idx).trim();
					return item;
				});
	
				formerror = r + " " + arr.map( (item) => {

					const last = arr.at(-1);

					if(last === item) {
						item = `and ${item}`;
					} else {
						item = item + ","
					}

					return item;
				}).join(" ");

				formerror = `Your are using a password which does not match our policies. ${formerror}.`;
			}
			break;
        case 'auth/user-disabled':
            formerror = "Your account has been disabled. Please contact us.";
            break;
        case 'auth/weak-password':
            formerror = "This is a very weak password. Try again";
            break;
        case 'auth/too-many-requests':
            formerror = "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
            break;
        case 'auth/user-cancelled':
        case 'auth/popup-closed-by-user':
            formerror = "The authencation was closed or cancelled. Please try again."
            break;
        case 'auth/network-request-failed':
            formerror = "We could not process your request. Please check your network and try again."
            break;
        case 'auth/timeout':
            formerror = "The authencation request timeout out as the required action was not taken within the required interval.";
            break;
        default:
            formerror = "There was an error while authenticating. Please contact support@chiroscript.ai for more help.";
            break;
    }

    return formerror;
}


export const getAppCheckToken = async (header = true) => {
	const { appCheck } = await import("./firebase");
    const { getLimitedUseToken } = await import('firebase/app-check');
    const token = (await getLimitedUseToken(appCheck)).token;

	if(!header) {
		return token
	}

	return {
		"X-Firebase-AppCheck": token
	}

}

export const getJWT = async (header = true) => {
	const { auth } = await import("./firebase");
	const { getIdToken } = await import("firebase/auth");
	const token = await getIdToken(auth.currentUser, true).then( (idToken) => idToken);

	if(!header) {
		return token
	}

	return {
		"Authorization": `Bearer ${token}`
	}
}

export async function getHeaders(appCheck = true, jwt = true, accept = "application/json", contentType = "application/json") {

	let headers = {
		"Accept": accept,
		"Content-Type": contentType,
		"Authorization": "none",
		"X-Firebase-AppCheck": "none"
	}

	if(!contentType) {
		delete headers['Content-Type']
	}

	if(!accept) {
		delete headers['Accept'];
	}

	if(jwt) {
		headers = {
			...headers,
			...await getJWT(true)
		};
	}

	if(appCheck) {
		headers = {
			...headers,
			...await getAppCheckToken(true),
		}
	}

	return headers;

}

export function getURL(...segments) {
	
	const validSegements = segments.filter((segment) => typeof segment === "string" && segment.length > 0).map( (item) => item.trim().toString());

	const URL = [import.meta.env["PUBLIC_API_URL"],...validSegements].join("/");

	return URL;

}


export const getTimezone = () => {
	let result;
	try {
		// Chrome, Firefox
		result = /.*\s(.+)/.exec(
			new Date().toLocaleDateString(navigator.language, {
				timeZoneName: "short",
			})
		)[1];
	} catch (e) {
		// IE, some loss in accuracy due to guessing at the abbreviation
		// Note: This regex adds a grouping around the open paren as a
		//       workaround for an IE regex parser bug
		result = new Date()
			.toTimeString()
			.match(new RegExp("[A-Z](?!.*[(])", "g"))
			.join("");
	}

	return result;
};

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));


/*
export async function saveMessagingDeviceToken(uid = null, save = false, count = 100) {
    if(count === 0) {
        return false;
    }
    try {
        const fcmToken = await getMessagingToken(messaging, {
            vapidKey: VAPID_KEY
        })
    
        if(fcmToken) {
            console.log('GOT FCM device token: ', fcmToken, " for uid: ", uid);
        }

        if(save && typeof uid === 'string' && uid.length > 0) {
            // Lets save it

            const {doc, updateDoc, arrayUnion } = await import('firebase/firestore');
            await updateDoc(doc(db, "users", uid), {
                fcmToken: arrayUnion(fcmToken)
            })
        }

        return fcmToken;
    } catch (err) {

        console.error( "Got an error, may be the sw failed: ", err.message );
        
        if(err.message.indexOf("PushManager") !== -1) {
            // call again
            return await saveMessagingDeviceToken(uid, save, (count-1))
        }
    }
}
*/
