import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';

const regex = /^(https?:\/\/)?([\w-]+\.)+(web\.app|firebaseapp\.com)$/

const isDefault = regex.test(window.location.hostname);

!isDefault ? (ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)) : window.location.replace(import.meta.env.PUBLIC_DEFAULT_DOMAIN)